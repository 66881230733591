import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import './App.css';

// for IE11 support
import "isomorphic-fetch"
import { polyfill } from 'es6-promise';
polyfill();

/*const Layout = React.lazy(() => import('./containers/Layout'));

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      localStorage.getItem('pingpongBizToken')
      ? <Component {...props} />
      : <Redirect to='/login' />
    )} />
  )
*/

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Pages
const FastFive2203 = React.lazy(() => import('./views/Pages/FastFive/2203'));
const SparkPlus2204 = React.lazy(() => import('./views/Pages/SparkPlus/2204'));

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/fastfive/2203" name="패스트파이브 베네핏" render={props => <FastFive2203 {...props}/>} />
            <Route exact path="/sparkplus/2204" name="스파크플러스 베네핏" render={props => <SparkPlus2204 {...props}/>} />

            {/*<PrivateRoute path="/" name="Home" component={Layout} />*/}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
